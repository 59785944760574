import ITransformedValue from '@/ship/Values/ITransformedValue';
import User from '@/ship/Models/User';

export enum ReplacementStatus {
    Created = 1,
    Replaced,
    Ended,
    Processing,
}

export interface IReplacement {
    id: number;
    affiluserId: number;
    userId: number;
    from: string;
    to: string;
    createdAt: string;
    updatedAt: string;
    status: ReplacementStatus;
    description?: string;

    user?: ITransformedValue<User>;
    affiluser?: ITransformedValue<User>;
}

export interface IReplacementEditModel {
    userId?: number;
    affilUserId?: number;
    from?: string;
    to?: string;
    description?: string;
    status?: ReplacementStatus;
}

export default IReplacement;
